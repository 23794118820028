import {Bars3Icon, ChevronDownIcon, XMarkIcon} from '@heroicons/react/24/outline';
import useResizeObserver from '@react-hook/resize-observer';
import classNames from 'classnames';
import Image from 'next/legacy/image';
import Link from 'next/link';
import {useRouter} from 'next/router';
import React, {ComponentPropsWithoutRef, useCallback, useEffect, useRef, useState} from 'react';

import URLS, {getResourcesURL} from '../../../urls';
import Col from '../../common/Col';
import Row from '../../common/Row';
import {useBreakpoint} from '../../common/util/useBreakpoint';
import useCurrentUser from '../../query/useCurrentUser';
import Button from './buttons/Button';
import SideNavigation from './SideNavigation';

type Props = ComponentPropsWithoutRef<'div'> & {
  scenarioIndex: number;
  onHeightChange: (height: number) => void;
};
const Navbar = ({className, scenarioIndex, onHeightChange, ...props}: Props) => {
  const {isMobile} = useBreakpoint('mobile');
  const {isTablet} = useBreakpoint('tablet');
  const router = useRouter();
  const {asPath} = router;
  const currentUser = useCurrentUser();
  const navBarRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [navBarHeight, setNavBarHeight] = useState<number>();
  const [isSubmenuExpanded, setIsSubmenuExpanded] = useState(false);

  const getActiveClassname = useCallback(
    (...paths: string[]) => {
      return paths.some(path => asPath.startsWith(path)) ? 'font-semibold' : undefined;
    },
    [asPath]
  );

  const blurActiveElement = useCallback(() => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }, []);

  // Callback handler that closes the collapsible navigation menu if active (focused) element is within the menu
  const handleCloseMenu = useCallback(() => {
    // Just for mobile screens
    if (!isTablet) return;

    if (menuRef.current?.contains(document.activeElement)) {
      setTimeout(() => {
        // Blur element on next cycle, after it has gained focus
        blurActiveElement();
        setIsSubmenuExpanded(false);
      }, 0);
    }
  }, [blurActiveElement, isTablet]);

  useEffect(() => {
    if (navBarRef.current) {
      const height = navBarRef.current.getBoundingClientRect().height;
      setNavBarHeight(height);
      onHeightChange(height);
    }
  }, [onHeightChange]);

  useResizeObserver(navBarRef, entry => {
    setNavBarHeight(entry.borderBoxSize[0].blockSize);
    onHeightChange(entry.borderBoxSize[0].blockSize);
  });

  return (
    <Row
      ref={navBarRef}
      className={classNames(
        'sticky top-0 z-50 justify-between items-center p-sm bg-white border-b border-gray-100',
        'tablet:bg-opacity-95 focus-within:bg-opacity-100 tablet:flex-row-reverse',
        className
      )}
      {...props}
    >
      <Link href={URLS.home} legacyBehavior className={'block'}>
        <a className={'leading-[1px]' /* Avoids extra bottom space on wrapping span generated by next/image */}>
          <Image
            src="/img/logo_navbar.svg"
            height={35}
            width={160}
            alt="Envirocrops logo"
            className={'cursor-pointer'}
            onClick={blurActiveElement}
          />
        </a>
      </Link>
      <div ref={menuRef} tabIndex={0} className={'group'} key={router.asPath}>
        <button
          className="hidden tablet:flex rounded-[1rem] border-thin border-gray-100 p-4 bg-white hover:group-focus-within:border-primary-300 hover:group-focus-within:text-primary-300"
          onMouseDown={handleCloseMenu}
        >
          <XMarkIcon className="w-sm hidden group-focus-within:flex" />
          <Bars3Icon className="w-sm flex group-focus-within:hidden" />
        </button>
        <div
          className={classNames(
            'flex flex-row space-x-md items-center bg-white',
            'tablet:hidden tablet:group-focus-within:flex tablet:flex-col tablet:items-stretch',
            'tablet:absolute tablet:-z-10 tablet:top-0 tablet:inset-x-0',
            'tablet:bg-transparent tablet:backdrop-blur-sm tablet:text-center',
            'tablet:overflow-y-scroll tablet:overscroll-y-none tablet:space-x-0 tablet:divide-gray-100 tablet:divide-y',
            '[&>*:last-child]:tablet:!border-b-thin [&>*:last-child]:tablet:border-b-gray-100',
            '[&>*]:tablet:flex-none'
          )}
          // Make sure menu appears just below navbar, and expands to the very bottom of the screen
          style={isTablet ? {height: `calc(100vh - ${navBarHeight}px)`, marginTop: navBarHeight} : undefined}
        >
          {isMobile && currentUser && (
            <Col className="w-full bg-white">
              <Button
                className={classNames(isSubmenuExpanded ? '!bg-white' : '!bg-primary-700')}
                variant="navigation"
                onMouseDown={() => setIsSubmenuExpanded(value => !value)}
              >
                <Row className={classNames('px-md justify-between', isSubmenuExpanded ? 'text-current' : 'text-white')}>
                  <div className="w-10" />
                  Dashboard
                  <ChevronDownIcon
                    className={classNames(
                      'w-sm h-sm transition-transform duration-300',
                      isSubmenuExpanded && '-scale-y-100'
                    )}
                  />
                </Row>
              </Button>
              {isSubmenuExpanded && (
                <div className="px-md">
                  <SideNavigation scenarioIndex={scenarioIndex} />
                </div>
              )}
            </Col>
          )}
          {!isMobile && isTablet && currentUser && (
            <div className="bg-white tablet:py-xs">
              <Button
                className="!block mx-sm"
                href={scenarioIndex === undefined ? URLS.dashboard : URLS.app(scenarioIndex).dashboard}
                variant="primary"
                onClick={handleCloseMenu}
              >
                Dashboard
              </Button>
            </div>
          )}
          <Button
            href={URLS.about}
            variant="navigation"
            data-text={'About'}
            className={getActiveClassname(URLS.about, URLS.becomeSupplier)}
          >
            About
          </Button>
          <Button
            href={URLS.game}
            variant="navigation"
            data-text={'Cropper Game'}
            className={getActiveClassname(URLS.game)}
          >
            Cropper Game
          </Button>
          <Button
            href={getResourcesURL()}
            variant="navigation"
            data-text={'Resources'}
            className={getActiveClassname(getResourcesURL(), '/resource')}
          >
            Resources
          </Button>
          <Button
            href={URLS.marketPlace.products}
            variant="navigation"
            data-text={'Marketplace'}
            className={getActiveClassname(URLS.marketPlace.products)}
          >
            Marketplace
          </Button>
          <Button
            href={URLS.marketPlace.suppliers}
            variant="navigation"
            data-text={'Find suppliers'}
            className={getActiveClassname(URLS.marketPlace.suppliers)}
          >
            Find suppliers
          </Button>
          {!isTablet && <div className="border-r border-gray-150 h-[3rem]" />}
          {!currentUser && (
            <Button
              href={URLS.signin}
              variant="navigation"
              data-text={'Login'}
              className={getActiveClassname(URLS.signin)}
            >
              Login
            </Button>
          )}
          {currentUser && !isTablet && (
            <Button
              href={scenarioIndex === undefined ? URLS.dashboard : URLS.app(scenarioIndex).dashboard}
              variant="navigation"
              className={getActiveClassname(URLS.app(scenarioIndex).dashboard)}
            >
              Dashboard
            </Button>
          )}
          {!currentUser && (
            <div className="bg-white tablet:py-xs">
              <Button className="!block mx-sm" href={URLS.start} variant="primary">
                Get Started
              </Button>
            </div>
          )}
        </div>
      </div>
    </Row>
  );
};

export default Navbar;
